export const BG_COVER = `https://ik.imagekit.io/farahoktarina/DimasAtina/1.%20Cover%20Potrait.jpg?updatedAt=1705277389937`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/farahoktarina/DimasAtina/2.%20Cover%20Dalam%20Potrait.jpg?updatedAt=1705277389853`;
export const BG_COUNTING = `https://user-images.githubusercontent.com/10141928/146292235-21d28e45-72b7-41e1-90ac-26a3605529e2.jpg`;
import bgFilter from '@/assets/bg/bg-filter.jpg'
export const BG_IG_FILTER = bgFilter;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahoktarina/DimasAtina/9.%20Dekstop%20Landscape.jpg?updatedAt=1705277389903`;

export const IMG_AYAT = `https://ik.imagekit.io/farahoktarina/DimasAtina/8.%20Footer%20Potrait.jpg?updatedAt=1705277389700`;
import logo from '@/assets/icons/logo.png'
export const IMG_LOGO = logo

export const IMG_MAN = `https://ik.imagekit.io/farahoktarina/DimasAtina/3.%20Groom.jpg?updatedAt=1705282187342`;
export const IMG_GIRL = `https://ik.imagekit.io/farahoktarina/DimasAtina/4.%20Bride.jpg?updatedAt=1705282187382`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/farahoktarina/DimasAtina/5.%20Cover%20Luar%20Galeri%201.jpg?updatedAt=1705277389821`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/farahoktarina/DimasAtina/6.%20Cover%20Luar%20Galeri%202.jpg?updatedAt=1705277389700`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/farahoktarina/DimasAtina/7.%20Cover%20Dalam%20Galeri.jpg?updatedAt=1705277389915`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
